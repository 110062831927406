import * as React from "react"
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import {graphql} from "gatsby";
import * as styles from "../styles/privacy-policy.module.scss";


const PrivacyPolicyPage = ({data}) => {
  console.log(data);
  return (
    <Layout contactInfo={data.api.contactInfo}>
      <Seo />
      <div className={styles.text} dangerouslySetInnerHTML={{__html: data.api.about?.privacyPolicy}} />
    </Layout>
  )
}

export const query = graphql`
  query {
      api {
          about:aboutSingleton {
              privacyPolicy
          }
          contactInfo:contactSingleton {
              email
              phone
              name
          }
      }
  }
`;

export default PrivacyPolicyPage;
